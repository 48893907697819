.current-session {
  margin: 0 5px;
  padding: 6px 10px 6px 6px;
  background-color: lightgray;
  border-radius: 4px;
}

.current-session pre {
  margin: 8px 5px 0 0;
  float: left;
}
