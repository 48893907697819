#volcano-container {
  height: calc(100% - 50px - 20px);
  width: calc(100% - 20px);
  min-height: 954px;
  min-width: 954px;
  margin: 10px;
}

#volcano-container .row {
  width: 100%;
}

/* MENU */

#volcano-menu-container {
  height: 150px;
  width: 100%;
  float: none;
}

#volcano-menu-container > div {
  float: left;
}

#volcano-menu-container > div.bp3-callout {
  height: 150px;
  width: 300px;
  margin-right: 15px;
}

#volcano-tabs div.bp3-tab-panel {
  height: 120px;
  width: 100%;
  padding: 2px;
  margin-top: 10px;
  overflow-y: scroll;
}

/* DIAGLOG */

.volcano-dialog div.bp3-dialog-body {
  height: 240px;
}

.volcano-confirm-step > div.bp3-callout {
  margin-bottom: 4px;
}

/* CHART */

#volcano-chart-container {
  height: calc(100% - 150px - 11px);
  min-height: 600px;
  width: 900px;
  margin: 0 auto;
}

#volcano-chart-container > div.row > div {
  float: left;
}
