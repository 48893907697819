.tutorial-container {
  height: calc(100% - 50px);
  width: 100%;
  padding: 10px;
}

.tutorial-container .tutorial-list {
  height: 200px;
  margin: 10px 0;
  overflow-y: scroll;
}

.tutorial-container .settings-container {
  width: 300px;
  padding: 10px;
}

.tutorial-container .inner {
  height: 100%;
}

.tutorial-container .bp3-callout {
  margin-bottom: 10px;
}

.tutorial-container .restore-state-switch {
  width: 100%;
  margin: 10px 0;
  padding-top: 5px;
  padding-left: 15px;
}

.tutorial-container .row {
  width: 100%;
  margin: 10px 0;
}

.tutorial-container .start-stop-button {
  width: calc(100% - 40px);
  float: left;
  margin-right: 10px;
}

.tutorial-container .instructions {
  height: calc(100% - 451px);
  overflow-y: scroll;
  padding: 10px;
  border-radius: 4px;
  box-shadow: inset 0 0 4px lightsteelblue;
  margin: 10px 0;
}

.tutorial-container .buttons {
  margin: 10px 0;
}

.tutorial-container .instructions p:last-child {
  margin: 0;
}
