.bp3-drawer {
  background: rgb(245, 245, 245);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bp3-drawer-header {
  height: 50px;
}

.cohorts-container {
  height: calc(100% - 50px);
  padding: 10px 15px;
}

.cohorts-container .bp3-tabs {
  height: 100%;
}

.cohorts-container .bp3-tab-list {
  width: 30%;
  margin: 0 auto;
}

.cohorts-container .bp3-tab-panel {
  height: calc(100% - 50px);
}

.cohorts-container .heading-with-icon {
  width: calc(100% - 18px);
  float: left;
}

.cohorts-container .heading-icon {
  float: left;
  margin-top: 4px;
}

.cohorts-container.pending {
  padding: 0;
}

.cohorts-container .list {
  height: calc(100% - (350px));
  width: 100%;
}

.cohorts-container .list .inner {
  height: 100%;
  width: 100%;
  margin: 0 0 5px 0;
  overflow: scroll;
  padding: 4px 8px 48px 4px;
  border-radius: 4px;
  background-color: white;
}

.cohorts-container .bp3-non-ideal-state {
  height: 45%;
  padding: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.cohorts-container .select-cohort-buttons-container {
  height: calc(24px);
  width: 100%;
  margin: 5px 0 0 0;
}

.cohorts-container .comparison-plot-container {
  height: calc(350px);
  width: 100%;
  float: none;
  padding: 0;
}

.cohorts-container .comparison-plot-container .inner {
  width: 50%;
  float: left;
  font-size: 12px;
}

.cohorts-container .bp3-label {
  margin-bottom: 5px;
  text-align: center;
}

.cohorts-container .comparison-plot-container .js-plotly-plot {
  width: 120px;
  margin: 0 auto;
}

.cohorts-container .element .buttons {
  position: relative;
  top: 0;
  left: 0;
  width: 24px;
  float: left;
}

.cohorts-container .element .cohort-body {
  width: calc(100% - 48px);
  float: left;
  padding: 0 5px;
}

.element .row.with-tags {
  height: 28px;
  overflow-x: scroll;
}

.element .row.small {
  height: 24px;
  width: 100%;
  padding-top: 4px;
}

.element .row.medium {
  height: 30px;
  width: 100%;
  padding: 4px 0;
}

.element .row.large {
  height: 40px;
  width: 100%;
  padding-top: 4px;
}

.element .row.with-tags::-webkit-scrollbar {
  display: none;
}

.row.with-tags .tag-container {
  width: max-content;
}

.row.with-tags .tag-container .bp3-tag {
  font-size: 10px;
}

.row .column-1-12 {
  width: 8.33%;
  float: left;
}

.row .column-2-12 {
  width: 16.65%;
  float: left;
}

.row .column-3-12 {
  width: 25%;
  float: left;
}

.row .column-4-12 {
  width: 33.33%;
  float: left;
}

.row .column-5-12 {
  width: 41.67%;
  float: left;
}

.row .column-6-12 {
  width: 50%;
  float: left;
}

.row .column-7-12 {
  width: 58.33%;
  float: left;
}

.row .column-8-12 {
  width: 66.67%;
  min-width: 66.67%;
  float: left;
}

.row .column-9-12 {
  width: 75%;
  min-width: 75%;
  float: left;
}

.row .column-10-12 {
  width: 83.35%;
  float: left;
}

.row .column-12-12 {
  width: 100%;
  float: left;
}

.element .buttons {
  border-radius: 4px;
}

.element .bp3-tag {
  float: left;
  margin: 0 4px 4px 0px;
}

.pending-cohorts-dialog {
  width: 650px;
}

.pending-cohorts-dialog .cohorts-container {
  margin-top: 10px;
}

.pending .element {
  height: max-content;
  margin: 5px;
  padding: 5px;
  background-color: #F5F8FA;
  border-radius: 3px;
  z-index: -1;
}

.pending-cohorts-dialog .element .pending-body {
  width: calc(100% - 40px);
  float: left;
  padding: 0 5px;
}

.pending .element .bp3-popover-wrapper {
  width: 30px;
}

.pending .element .id-count.bp3-tag {
  width: calc(50% - 20px);
  float: left;
  margin: 0 4px 4px 0px;
}

.pending .bp3-tag-input-values .bp3-tag {
  margin: 0 5px 5px 0;
}

.highlighted-cohort-dialog {
  /* width: 650px; */
}

.highlighted-cohort-dialog .cohort-name-container {
  margin-bottom: 10px;
}

.highlighted-cohort-dialog .cohort-counts-container {
  height: calc(33px + 34px);
  margin: 10px 0;
}

.highlighted-cohort-dialog .id-count.bp3-tag {
  width: calc(50% - 5px);
  float: left;
  margin: 0 4px 4px 0px;
}

.highlighted-cohort-dialog .tag-container {
  margin: 10px 0;
}

.highlighted-cohort-dialog .tag-container .bp3-tag {
  margin: 0 5px 0 0;
}

.cohorts-container .list.pending {
  margin-top: 10px;
}

.cohorts-container .list.pending .inner {
  height: 240px;
}
