.treatment-filter-list {
  height: 150px;
  margin: 10px 0 5px 0;
  overflow-y: scroll;
  background-color: rgb(245, 245, 245);
  border-radius: 3px;
}

.treatment-filter-container .bp3-popover-target {
  width: 100%;
}

.treatment-filter-buttons-container {
  height: 24px;
  margin: 4px 0;
}

.treatment-filter-container .treatment-filter-buttons {
  width: 49%;
}

.treatment-filter-buttons.left {
  float: left;
}

.treatment-filter-buttons.right {
  float: right;
}

.treatment-presets .bp3-blockquote {
  margin: 2px;
  padding: 0 10px;
  font-size: 10px;
  text-align: justify;
}
