.data-field-picker-container {
  width: 100%;
}

.data-field-picker-container .bp3-popover-target {
  width: 100%;
}

.data-field-picker-container .bp3-overlay {
  width: 0;
}

.data-field-swapper-container .bp3-popover-target {
  width: 100%;
}

.data-field-swapper-container .bp3-overlay {
  width: 0;
}

.data-field-select .bp3-select-popover, .data-field-select .bp3-multi-select-popover {
  height: 200px;
  width: 400px;
  background: white;
}

.data-field-select .bp3-popover-content {
  padding: 2px;
}

.data-field-label-tag-container .bp3-tag {
  margin-right: 2px;
  text-align: center;
}

.data-field-label-tag-container .bp3-tag:last-child {
  margin-right: 0px;
}

.source-picker-container .bp3-popover-target {
  width: 100%;
}

.source-picker-container .bp3-overlay {
  width: 0;
}
