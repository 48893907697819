.search-container .cohort-container {
  height: 800px;
  padding: 12px;
  overflow: scroll;
}

.cohort-container .cohort {
  width: 100%;
  padding: 6px;
  overflow: scroll;
  background: #eee;
  border-radius: 5px;
  margin-bottom: 5px;
  overflow: scroll;
}

.cohort-container .cohort > .left {
  width: 60px;
  float: left;
}

.cohort-container .cohort > .left .bp3-button-group {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cohort-container .cohort > .left .bp3-button {
  margin-bottom: 2px;
}

.cohort-container .cohort > .right {
  width: calc(100% - 60px);
  float: left;
}

.cohort-container .cohort .name {
  font-weight: 500;
}

.search-tag-container {
  display: flex;
  justify-content: space-between;
}

.search-cohort-tag-container > .bp3-tag {
  margin: 2px 0 0 2px;
}
