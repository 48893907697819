.statistics-container {
  height: 100%;
  width: 100%;
  max-width: 400px;
}

.statistics-container .bp3-tabs {
  height: inherit;
  width: inherit;
}

.statistics-container .bp3-tab-panel {
  height: calc(100% - 30px - 15px);
  margin-top: 15px;
}

.statistics-container .bp3-tab-panel > div {
  height: inherit;
}

.statistics-container .list {
  height: 100%;
  overflow: scroll;
  padding-right: 12px;
}

/* TAB TITLE LIST */
.bp3-tab-list {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bp3-tab-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/* GENERAL */
.statistics-container .cohort-label {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  padding-right: 5px;
}

.statistics-container .bp3-icon {
  margin-right: 5px;
}

.statistics-container .cohort-container {
  width: calc(100% - 21px);
  float: left;
  margin-left: 21px;
}

/* LEGEND TAB */
.legend-container > div {
  margin: 10px 0;
}

.legend-container > div:first-child {
  margin-top: 0;
}

.legend-container > div:last-child {
  margin-bottom: 0;
}

.sample-type-cell {
  width: 50%;
  float: left;
}

.oncoprint-legend {
  height: calc(18px * 6);
}

.hla-zygosity-legend {
  height: calc(18px * 2);
}

.hed-density-legend {
  height: calc(18px * 3);
}

.oncoprint-legend .column > div,
.hla-zygosity-legend .column > div,
.hed-density-legend .column > div {
  float: left;
  width: 50%;
}

.expression-heatmap-legend .bp3-slider,
.hla-heatmap-legend .bp3-slider {
  width: calc(100% - 24px);
  margin-left: 12px;
}

.expression-heatmap-legend .bp3-slider-track {
  height: 10px;
  background: rgb(2,8,172);
  background: -moz-linear-gradient(90deg, rgba(2,8,172,1) 0%, rgba(77,101,226,1) 25%, rgba(190,190,190,1) 50%, rgba(221,122,80,1) 75%, rgba(178,11,29,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(2,8,172,1) 0%, rgba(77,101,226,1) 25%, rgba(190,190,190,1) 50%, rgba(221,122,80,1) 75%, rgba(178,11,29,1) 100%);
  background: linear-gradient(90deg, rgba(2,8,172,1) 0%, rgba(77,101,226,1) 25%, rgba(190,190,190,1) 50%, rgba(221,122,80,1) 75%, rgba(178,11,29,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0208ac",endColorstr="#b20b1d",GradientType=1);
}

.expression-heatmap-legend .bp3-slider-progress,
.expression-heatmap-legend .bp3-slider-handle ,
.hla-heatmap-legend .bp3-slider-progress,
.hla-heatmap-legend .bp3-slider-handle {
  display: none;
}

.hla-heatmap-legend .bp3-slider-track {
  background: rgb(49,54,149);
  background: -moz-linear-gradient(90deg, rgba(49,54,149,1) 0%, rgba(69,117,180,1) 11%, rgba(116,173,209,1) 22%, rgba(171,217,233,1) 33%, rgba(224,243,248,1) 44%, rgba(254,224,144,1) 55%, rgba(253,174,97,1) 66%, rgba(244,109,67,1) 77%, rgba(215,48,39,1) 88%, rgba(165,0,38,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(49,54,149,1) 0%, rgba(69,117,180,1) 11%, rgba(116,173,209,1) 22%, rgba(171,217,233,1) 33%, rgba(224,243,248,1) 44%, rgba(254,224,144,1) 55%, rgba(253,174,97,1) 66%, rgba(244,109,67,1) 77%, rgba(215,48,39,1) 88%, rgba(165,0,38,1) 100%);
  background: linear-gradient(90deg, rgba(49,54,149,1) 0%, rgba(69,117,180,1) 11%, rgba(116,173,209,1) 22%, rgba(171,217,233,1) 33%, rgba(224,243,248,1) 44%, rgba(254,224,144,1) 55%, rgba(253,174,97,1) 66%, rgba(244,109,67,1) 77%, rgba(215,48,39,1) 88%, rgba(165,0,38,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#313695",endColorstr="#a50026",GradientType=1);
}

.hla-allele-pair-legend {
  margin-bottom: 20px;
}

/* POTENTIAL VALUES */
.potential-values-container .bp3-slider {
  height: 18px;
  width: calc(100% - 50px);
  min-width: unset;
  float: left;
  margin-bottom: 4px;
}

.potential-values-container .slider-container .bp3-slider-track {
  right: 4px;
}

.potential-values-container .slider-container .bp3-slider-axis .bp3-slider-label:first-child {
  display: none;
}

.potential-values-container .slider-container .bp3-slider-handle {
  width: 0;
  box-shadow: none;
}

.potential-values-container .slider-container .bp3-slider-axis .bp3-slider-label:last-child {
  transform: translate(-2px, 0px);
  -webkit-transform: translate(-2px, 0px);
  box-shadow: none;
}

.potential-values-container .slider-container .bp3-slider-handle .bp3-slider-label {
  transform: translate(-6px, 0px);
  -webkit-transform: translate(-6px, 0px);
  box-shadow: none;
}

/* QUARTILES & VALUES */
.quartiles-container .buttons,
.values-container .buttons,
.variants-container .buttons {
  width: 100%;
  float: left;
  margin: 2px 0;
}

.quartiles-container .buttons .bp3-button,
.values-container .buttons .bp3-button,
.variants-container .buttons .bp3-button {
  min-width: 18px;
  min-height: 18px;
  padding: 0 6px;
}

.quartiles-container .buttons .bp3-button-text,
.values-container .buttons .bp3-button-text,
.variants-container .buttons .bp3-button-text {
  font-size: 10px;
}

.quartiles-container .push-right,
.values-container .push-right,
.variants-container .push-right {
  width: calc(100% - 21px);
  float: left;
  margin-left: 21px;
}

.quartiles-container .gene-label,
.variants-container .gene-label {
  margin: 2px 0;
}

.variants-container .buttons .bp3-button-text {
  width: 140px;
  text-align: center;
}

/* DATA FIELD LEGEND */
.data-field-legend-container .numerical-data-field {
  margin-bottom: 10px;
}

.data-field-legend-container .categorical-data-field {
  margin-bottom: 4px;
}

.data-field-legend-container .categorical-value {
  margin-left: 21px;
}

.data-field-legend-container .categorical-value:last-child {
  margin-bottom: 10px;
}
