.global-control-textarea {
  width: 300px;
}

.global-control-textarea textarea {
  width: calc(100% - 4px);
  outline: none;
  border: none;
  resize: none;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.2s ease-in-out;
}

.global-control-textarea textarea:focus {
  background-color: rgba(19, 14, 238, 0.3);
  transition: background-color 0.1s ease-in-out;
}

.feedback-line {
  height: 4px;
  width: 100%;
  left: 0px;
  top: -8px;
  position: relative;
  border-radius: 1px;
  background-color: rgba(19, 14, 238);
}

.animationOne {
  animation: sampleanimationone 1.5s linear forwards;
}

.animationTwo {
  animation: sampleanimationtwo 1.5s linear forwards;
}

@keyframes sampleanimationone {
  0% {
    width: 1%;
    background-color: rgba(19, 14, 238, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(19, 14, 238);
  }
}

@keyframes sampleanimationtwo {
  0% {
    width: 1%;
    background-color: rgba(19, 14, 238, 0.3);
  }
  100% {
    width: 100%;
    background-color: rgba(19, 14, 238);
  }
}
