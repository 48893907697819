.analysis-container {
  height: calc(100% - 50px - 20px);
  width: calc(100% - 20px);
  min-height: 954px;
  min-width: 954px;
  margin: 10px;
}

.analysis-container .row {
  width: 100%;
}

/* MENU */

.analysis-menu-container {
  height: 180px;
  width: 100%;
  float: none;

}

.analysis-menu-container > div {
  width: calc(100% - 300px - 15px - 10px);
  max-width: 580px;
  height: 180px;
  float: left;
  overflow-x: scroll;
}

.analysis-menu-container > div.bp3-callout {
  height: 180px;
  width: 300px;
  margin-right: 15px;
}

.analysis-tabs div.bp3-tab-panel {
  height: calc(180px - 30px - 10px);
  width: 100%;
  padding: 2px;
  margin-top: 10px;
  overflow-y: scroll;
}


/* CHART */

.analysis-chart-container {
  height: calc(100% - 180px - 11px);
  min-height: 600px;
  margin: 0 auto;
}
