/* Publiched Cohort card */

.published-cohort {
  height: 54px;
}

.published-cohort .element {
  height: 100%;
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  background-color: #CED9E0;
}

.published-cohort .element.selected {
  background-color: #3DCC91;
}

.published-cohort .element .buttons {
  position: relative;
  top: 0;
  left: 0;
  width: 24px;
  float: left;
}

.published-cohort .element .cohort-body {
  width: calc(100% - 24px);
  float: left;
  padding: 0 5px;
}

.published-cohort .element .row.title {
  height: 22px;
  padding-top: 4px;
  font-weight: bold;
}

.published-cohort .element .row.patient-counts {
  height: 22px;
  padding-top: 4px;
}

.published-cohort .row.patient-counts div.spaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.published-cohort .row.title div.icons-group {
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.published-cohort .icon-as-button {
  cursor: pointer;
}

.published-cohort .row.title .icons .bp3-icon:first-child {
  margin-right: 4px;
}

/* Mapping Popover */

.mapping-popover-container .bp3-popover-wrapper {
  height: 30px;
  width: 100%;
}

.mapping-popover-container .bp3-overlay {
  height: 30px;
  width: 0;
}

.sample-mapping-popover {
  width: 300px;
  padding: 10px;
}

.mapping-popover-container .bp3-label {
  text-align: left;
}

/* Highlighted Published Cohort Dialog */

.highlighted-published-cohort-dialog .heading-container > * {
  margin-bottom: 10px;
}

.highlighted-published-cohort-dialog .authors-container {
  height: 18px;
  width: 100%;
}

.highlighted-published-cohort-dialog .authors-container > .first-authors {
  float: left;
  width: 70%;
}

.highlighted-published-cohort-dialog .authors-container > .last-authors {
  float: right;
  width: fit-content;
}

.highlighted-published-cohort-dialog .abstract-container {
  text-align: justify;
}

.highlighted-published-cohort-dialog .link-container {}

.highlighted-published-cohort-dialog .cancer-type-container > button {
  margin: 0 4px 4px 0;
}
