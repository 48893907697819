.ioexplorer-plot {
  height: 100%;
  width: 100%;
  max-width: 1024px;
  max-height: 600px;
  margin: auto;
}

.bp3-tabs .ioexplorer-plot {
  height: calc(100%);
  width: 100%;
  max-width: 1024px;
  max-height: 500px;
  margin: auto;
}

.plot-container .bp3-tab-list {
  width: calc(186px + 20px + 211px);
  margin: 0 auto;
}
