.confidence-interval-picker-container .bp3-popover-target {
  width: 100%;
}

.confidence-interval-picker-container .bp3-overlay {
  width: 0;
}

.confidence-interval-picker {
  width: 100%;
  padding: 0 15px;
}

.logrank-weighting-container .bp3-popover-target {
  width: 100%;
}

.logrank-weighting-container .bp3-overlay {
  width: 0;
}

.confidence-interval-picker-container .bp3-slider {
  width: calc(100% - 30px);
  margin: 0 15px;
}

.survival-statistics .bp3-control-group {
  margin-bottom: 2px;
}

.survival-statistics .bp3-control-group .bp3-tag:last-child {
  text-align: right;
}