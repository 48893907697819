.gene-picker-container {
  width: 100%;
}

.gene-picker-container .bp3-tag-input-values {
  max-height: 87px;
  overflow-y: scroll;
  margin-right: 0px;
}

.sort-by-container {
  width: 100%;
}

.sample-order-container .bp3-icon,
.scale-container .bp3-icon,
.normalize-container .bp3-icon,
/* blueprintjs override */ .bp3-control-group:not(.bp3-vertical) > *:not(.bp3-divider) {
  margin-right: 6px;
}

.sample-order-container .bp3-popover-wrapper {
  position: absolute;
  right: 0px;
}

.sample-order-container .bp3-select-popover {
  width: 200px;
  max-height: 120px;
}

.sample-order-container .bp3-button {
  min-height: 16px;
}

.data-field-scale-container .data-field {
  width: 100%;
  float: left;
}

.gene-identifiers-row .bp3-tag {
  margin-left: 3px;
}
