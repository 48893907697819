.analysis-module {
  height: calc(100% - 50px);
  width: 100%;
}

.parameter-container > div {
  margin: 10px 0;
}

.source-picker {
  height: 46px;
  width: 100%;
}
