.comment-tooltip-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 54px;
  width: 60px;
}

.comment-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.comment-textarea {
  max-height: 400px;
  overflow-y: scroll;
}

.comment-container .form {
  margin-top: 15px;
}

.comment-container .bp3-popover-target {
  width: 100%;
}

.comment-container .bp3-menu-item {
  width: 450px;
}
